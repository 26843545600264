// ** React Imports
// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'
import axios from 'axios'
// ** PrismJS
import 'prismjs'
import 'prismjs/components/prism-jsx.min'
import 'prismjs/themes/prism-tomorrow.css'
import React, {lazy, Suspense, useContext} from 'react'
import ReactDOM from 'react-dom'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// ** Redux Imports
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
// ** Ripple Button
import './@core/components/ripple-button'
// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/scss/core.scss'
import './index.scss'
// ** Fake Database
// import './@fake-db'
import './assets/scss/style.scss'
// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
// ** i18n
import './configs/i18n'
import { store } from './redux/store'
import { AppDefaultsProvider } from './utility/context/AppDefaults'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import i18n from "i18next";
import {handleUserProfile} from "./redux/authentication";


import { register } from './serviceWorker';


// ** Service Worker
import * as serviceWorker from './serviceWorker'
import {serverUrlAddress} from "./utility/Utils";

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

if (!!process.env.REACT_APP_ENV) {

  axios.defaults.baseURL = (localStorage.getItem(serverUrlAddress)??process.env[`REACT_APP_API_URL_${process.env.REACT_APP_ENV}`]) + "/en/";

  // const newColor = '#ff6347'; // New color value
  // document.documentElement.style.setProperty('--bs-primary', newColor);

  if (process.env.REACT_APP_ENV === 'dev') {
    const observer = new MutationObserver(() => document.querySelector('iframe')?.remove())
    observer.observe(document.getRootNode(), { attributes: true, childList: true, subtree: true })
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <AppDefaultsProvider>
              <LazyApp />
              <ToastContainer newestOnTop />
            </AppDefaultsProvider>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </React.StrictMode>
  , document.getElementById('root')
)

register();

// ReactDOM.MutationObserver

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register();